<template>
    <div class="Encyclopedia">
        <div>
            <div class="container">
                <Head></Head>
            </div>
            <div class="container content">
                <MainMenu ref="MainMenu"></MainMenu>
                <div class="Inner">
                    <div class="leftMenu" v-loading="categories.loading">
                        <el-menu :default-active="activeIndex"
                                 v-if="!categories.loading"
                                 mode="vertical"
                                 @select="handleSelect">
                            <el-menu-item :index="item.id" v-for="(item,i) in categories.list"
                                          :key="item.id">
                                {{isEn ? item.longtitle : item.pagetitle}}
                            </el-menu-item>
                        </el-menu>
                    </div>
                    <div class="wrap" ref="wrap">

                        <div class="object-page" v-if="show.objectPage">
                            <div class="navigation">
                                <div @click="handleBack()" class="linkLike">
                                    <i class="far fa-arrow-alt-left ml-1"></i>
                                    {{$t('back')}}
                                </div>
                            </div>
                            <mdx-page type="article"
                                      :alias="objectToShow.alias"
                            ></mdx-page>
                        </div>

                        <div class="object-list" v-else>
                            <div class="pageTitle">
                                <h1 v-html="isEn ? objectList.longtitle : objectList.pagetitle"></h1>
                            </div>
                            <ul class="dashed">
                                <li v-for="item in objectList.pages"
                                    v-html="isEn ? item.longtitle : item.pagetitle"
                                    @click="handleSelectObject(item)"
                                    :key="item.id"></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import MainMenu from "../components/MainMenu";
    import Head from "../components/Head";
    import MdxPage from "../components/widgets/MdxPage";
    import req from "../commons/req";

    export default {
        name: "Encyclopedia",
        components: {Head, MainMenu, MdxPage},
        props: {},
        data() {
            return {
                alias: '',
                activeIndex: '0',
                curIndex: '',
                objectToShow: {},
                objectList: [],
                page: 1,
                show: {
                    objectPage: false,
                },
            }
        },
        computed: {
            locale() {
                return this.$store.getters.locale;
            },
            isEn() {
                return this.$store.getters.locale === 'en'
            },
            categories(){
                return this.$store.getters.encyclopediaTree;
            }
        },

        mounted() {
            this.$refs.MainMenu.activeIndex = 'encyclopedia';
            this.getData();
        },
        watch: {},
        methods: {
            refresh() {
            },
            getData() {
                let self = this;
                self.$store.dispatch('getEncyclopedia', this.locale).then(() => {
                    console.log('--enc', self.categories);
                    self.activeIndex = self.curIndex = self.categories.list[0].id;
                    self.showCategory();
                });
            },
            showCategory() {
                let self = this,
                    _cat = self.categories.list.find(it => it.id === self.curIndex);
                self.objectList = _.cloneDeep(_cat);
            },
            handleSelect(index) {
                let self = this;
                self.curIndex = index;
                self.page = 1;
                self.show.objectPage = false;
                self.objectToShow = {};
                self.$refs.wrap.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                self.showCategory();
            },
            handleSelectObject(item) {
                let self = this;
                self.show.objectPage = true;
                self.objectToShow = item;

            },
            handleBack(){
                let self = this;
                self.show.objectPage = false;
                self.objectToShow = {};

            }

        },
    }
</script>

<style lang="scss">
    .Encyclopedia {
    }
</style>
<style lang="scss" scoped>
    .wrap {
        padding-top: 15px;
        padding-bottom: 10px;
    }
    .navigation{
        margin-bottom: 9px;
        font-size: .883rem;
    }
    .object-list{
        li{
            margin-bottom: 10px;
        }
    }
</style>